<template>
  <div>
    <v-breadcrumbs v-if="!isEmbeddedInApp" :items="breadcrumbItems"/>

    <v-card v-if="event" :elevation="isEmbedded ? 0 : 2">
      <v-alert type="warning" v-if="event.notPublished">This event is not published and not visible on live site.</v-alert>
      <EventHeader v-if="!isEmbeddedInApp" :event="event"/>
      <EventFriendReferral :event="event"/>

      <v-alert v-if="isConnected" tile prominent type="success">
        <p>{{$t('events.welcome.joined-message', {event: event.name})}}</p>
        <p>
          <span v-if="status.verification_code">{{$t('events.welcome.joined-code', {code: status.verification_code})}}</span>
          <span v-if="status.races">{{$t('events.welcome.joined-races', {races: status.races.join(', ') })}}</span>
        </p>
        <p v-if="status.reg_name">
          <i18n path="events.welcome.reg-name">
            <strong >{{status.reg_name}}</strong>
          </i18n>
          </p>
      </v-alert>

      <v-card-title class="headline">{{$t('events.welcome.how-it-works')}}</v-card-title>
      <v-card-text v-if="event.instructions">
        <vue-markdown class="markdown" :html="false">{{ event.instructions }}</vue-markdown>
      </v-card-text>
      <div v-if="isConnected">
        <v-card-text>

            <div class="mb-4">
              <v-btn v-if="status && status.show_team_management" class="mr-4" :to="{name: 'profileGroups'}">Manage your team</v-btn>
              <v-btn v-if="status && status.allow_custom_team && !event.join_block_team_create" class="mr-4" @click="createCustomGroup">Create a new team</v-btn>
              <v-btn v-if="status && status.allow_custom_team" class="mr-4" @click="joinCustomGroup">Join a team</v-btn>
              
              <v-dialog v-if="status && status.allow_joining_another_race" v-model="joinAnotherDialog" max-width="400px">
                <template v-slot:activator="{ on }">
                  <v-btn outlined color="green" class="mr-4" v-on="on"><v-icon small class="mr-2">fa fa-plus</v-icon> {{$t('events.welcome.join-race')}}</v-btn>
                </template>
                <v-card>
                  <v-card-title>{{$t('events.welcome.join-another-race')}}</v-card-title>
                  <v-card-text>
                    {{$t('events.welcome.select-race-to-join')}}
                  </v-card-text>
                  <v-divider></v-divider>
                  <v-card-text>
                    <v-radio-group v-model="selectedRaceId">
                      <v-radio v-for="(item, idx) in event.races.filter(x=>!x.team)" :key="idx" :label="item.name" :value="item.id"/>
                    </v-radio-group>
                  </v-card-text>
                  <v-divider></v-divider>
                  <v-card-actions>
                    <v-btn color="secondary" text @click="joinAnotherDialog = false">{{$t('shared.cancel')}}</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="secondary" :disabled="!selectedRaceId" @click="joinAnotherRace();joinAnotherDialog = false">{{$t('shared.confirm')}}</v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>              
              
              <v-dialog v-if="status && status.allow_custom_race_selection" v-model="selectRacesDialog" max-width="400px">
                <template v-slot:activator="{ on }">
                  <v-btn outlined color="green" class="mr-4" v-on="on"><v-icon small class="mr-2">fa fa-plus</v-icon> {{$t('events.welcome.select-races')}}</v-btn>
                </template>
                <v-card>
                  <v-card-title>{{$t('events.welcome.select-races')}}</v-card-title>
                  <v-card-text>
                    {{$t('events.welcome.select-races-to-join')}}
                  </v-card-text>
                  <v-divider></v-divider>
                  <v-card-text>
                    <v-checkbox v-for="(item, idx) in event.races.filter(x=>!x.team && !x.on_demand)" :key="idx" v-model="selectedRaceIds" :label="item.name" :value="item.id" hide-details></v-checkbox>
                  </v-card-text>
                  <v-divider></v-divider>
                  <v-card-actions>
                    <v-btn color="secondary" text @click="selectRacesDialog = false">{{$t('shared.cancel')}}</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="secondary" @click="customizeRaces();selectRacesDialog = false">{{$t('shared.confirm')}}</v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>              
              
              <v-dialog v-if="!isEmbeddedInApp" v-model="disconnectDialog" max-width="400px">
                <template v-slot:activator="{ on }">
                  <v-btn text color="red" v-on="on"><v-icon small class="mr-2">fa fa-unlink</v-icon> {{$t('events.welcome.disconnect')}}</v-btn>
                </template>
                <v-card>
                  <v-card-title>{{$t('events.welcome.disconnect-title')}}</v-card-title>
                  <v-card-text>
                    {{$t('events.welcome.disconnect-message')}}
                  </v-card-text>
                  <v-divider></v-divider>
                  <v-card-text>
                    <v-switch v-model="deleteResults" class="mt-4" persistent-hint :hint="$t('events.welcome.disconnect-delete-results')" :label="$t('events.welcome.disconnect-delete-results-switch')"></v-switch>
                  </v-card-text>
                  <v-divider></v-divider>
                  <v-card-actions>
                    <v-btn color="secondary" text @click="disconnectDialog = false">{{$t('shared.cancel')}}</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="secondary" @click="disconnectEvent();disconnectDialog = false">{{$t('shared.confirm')}}</v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>              
            </div>

            <div v-if="onDemandRacesAvailable && onDemandRacesAvailable.length > 0" class="mb-4">
              <h3 class="subtitle">Start a On-Demand Challenge</h3>
              <p>Fancy an extra challenge? Pick one of the optional challenges from below to get started.</p>
              <v-sheet v-for="(item, idx) in onDemandRacesAvailable" :key="idx" elevation="2" color="grey lighten-4" class="mb-2 pa-4">
                <h3>{{item.name}}</h3>
                <p>{{item.description}}</p>
                <p>
                  <v-btn text :color="event.color || 'primary'" @click="joinAnotherRace(item.id)">Start</v-btn>
                </p>
              </v-sheet>

            </div>

            <div v-if="!isEmbeddedInApp" class="mb-4">
              <h3 class="subtitle">{{$t('events.welcome.customize-title')}}</h3>
              <p>{{$t('events.welcome.customize-intro')}}</p>
              <v-alert v-if="profile && profile.random_name" tile type="info">
                <i18n path="profile.name-intro">
                  <strong>{{profile.name}}</strong>
                </i18n>
              </v-alert>
              <v-btn :to="{name:'profileEdit'}" :color="event.color || 'primary'" :dark="$helpers.isDarkColor(event.color || 'primary')" class="mr-4">{{$t('events.welcome.personalize')}}</v-btn>
              <v-btn :to="{name: 'profile'}">{{$t('events.welcome.view-profile')}}</v-btn>
            </div>

            <v-container v-if="!isEmbeddedInApp" class="pa-0">
              <v-row>
                <v-col v-if="event.allow_manual_entry" cols="12" sm="4">
                  <div>
                    <h3 class="subtitle">{{$t('events.welcome.manual-entry-or-upload')}}</h3>
                    <p>{{$t('events.welcome.manual-entry-description')}}</p>
                    <v-btn outlined :color="event.color || 'primary'" :to="{name: 'activityEntry'}" class="mb-4" exact>{{$t('profile.activities.manualentry')}}</v-btn>
                  </div>
                  <!--<h3 v-else class="subtitle">{{$t('events.welcome.manual-upload')}}</h3>
                  <p>{{$t('events.welcome.manual-upload-description')}}</p>
                  <v-btn outlined color="primary" :to="{name: 'activityUpload'}" exact>{{$t('profile.activities.upload')}}</v-btn>-->
                </v-col>
                <v-col v-if="false" cols="12" sm="4">
                  <h3 class="subtitle">{{$t('events.welcome.strava-instructions')}}</h3>
                  <p>
                    {{$t('events.welcome.strava-message')}}
                  </p>
                  <p>
                    {{$t('events.welcome.strava-tip')}}
                  </p>
                </v-col>
                <v-col cols="12" sm="4">
                  <h3 class="subtitle">{{$t('profile.connections.manage')}}</h3>
                  <p>
                    {{$t('events.welcome.connections-message')}}
                  </p>
                  <v-btn outlined :to="{name: 'profileConnections'}" :color="event.color || 'primary'">{{$t('profile.manage-connections')}}</v-btn>
                </v-col>
              </v-row>
            </v-container>
            <div v-if="status.provider == 'sodisp'">
            </div>
            <!-- <v-btn class="mt-4" color="primary" outlined @click="testProfile">Test profile</v-btn> -->
        </v-card-text>
      </div>
      <v-card-text v-else>
        <EventJoinCard :event="event"/>
      </v-card-text>

      <div v-if="event.races && !isEmbeddedInApp">
        <v-card-title class="headline">{{$t('events.race.races')}}</v-card-title>
        <v-card-text>{{$t('events.welcome.race-sync-tip')}}</v-card-text>
        <RaceGrid :items="event.races" :event="event" class="px-4 mb-8"></RaceGrid>
      </div>
    
      <v-divider></v-divider>

      <div class="grey lighten-4 ">
        <v-card-text>
          <vue-markdown v-if="event.rules_desc" class="markdown" :html="false">{{event.rules_desc }}</vue-markdown>
          <div v-else>
            <h3 class="headline">{{$t('events.rules.title')}}</h3>
            <ul class="mb-4 mt-2">
              <li>{{$t('events.rules.join_before', { from: $options.filters.localDate(event.from, "L LT"), till: $options.filters.localDate(event.till, "L LT") })}}</li>
              <li>{{$t('events.rules.period', { from: $options.filters.localDate(event.from, "L LT"), till: $options.filters.localDate(event.till, "L LT") })}}</li>
              <li v-if="event.allowed_history_days || event.allowed_history_days === 0">{{$t('events.rules.backloading', { days: event.allowed_history_days })}}</li>
              <li v-if="event.allow_manual_entry">{{$t('events.rules.manual-entry-allowed')}}</li>
              <li v-else>{{$t('events.rules.gps-only')}}</li>
            </ul>

            <p>
              {{$t('events.local-date-times-msg')}}
            </p>
          </div>
        </v-card-text>
        
      </div>
      <SodispRules v-if="event.distancing"/>
      <p v-if="user" class="text-muted">Logged in</p>

    </v-card>
  </div>
</template>



<script>
import { mapGetters } from "vuex";
import eventService from "@/services/eventService";
import SodispRules from '@/components/SodispRules.vue';
import EventHeader from '@/components/EventHeader.vue';
import EventJoinCard from '@/components/EventJoinCard.vue';
import EventFriendReferral from '@/components/EventFriendReferral.vue';
import RaceGrid from '@/components/RaceGrid.vue';
import profileService from "@/services/profileService";
import { EventBus } from '@/plugins/eventbus.js';
import VueMarkdown from '@/components/VueMarkdown.vue'
import tenants from '@/data/tenants.config'
const tenant = tenants.current();

export default {
  name: "Event",
  components: {
    SodispRules,
    EventHeader,
    EventJoinCard,
    EventFriendReferral,
    VueMarkdown,
    RaceGrid,
  },
  props: {
  },
  data() {
    return {
      event: null,
      status: null,
      profile: null,
      selectedRaceId: null,
      selectedRaceIds: [],
      disconnectDialog: false,
      joinAnotherDialog: false,
      selectRacesDialog: false,
      deleteResults: false,
    };
  },
  async beforeRouteUpdate (to, from, next) {
    // react to route changes...
    await this.loadEvent(to.params.id);
    next();
  },

  async mounted() {
    await this.loadEvent(this.$route.params.id);
    
    EventBus.$on('login-state-change', async user => {
      //console.log('LOGIN CHANGED', user, this);
      await this.getEventStatus();
    });
    await this.getEventStatus();
  },

  methods: {
      
    async getEventStatus() {
      if (this.user) {
        var verificationCode = this.$route.query.verificationCode;
        var response = await profileService.getEventStatus(this.event.id);
        this.status = response.data;
        this.profile = (await profileService.get(/*full:*/false)).data;
        this.selectedRaceIds = this.status.race_ids || [];

        if (this.status && this.status.verification_required){
          this.$router.replace({ name: "eventJoin", params: { id: this.event.id}, query: {verificationCode: verificationCode} });
          return;
        }
      }
    },
    
    async disconnectEvent() {
      //if (window.confirm('Are you sure you want to disconnect from this event?')) {
        await profileService.disconnectEvent(this.event.id, this.deleteResults);
        this.$router.replace({ name: "event", params: { event: this.event.id} });
      //}
      //await this.getEventStatus();
    },
    
    async joinAnotherRace(id) {
      //if (window.confirm('Are you sure you want to disconnect from this event?')) {
        await profileService.joinRace(this.event.id, this.selectedRaceId || id);
        this.$router.replace({ name: "event", params: { event: this.event.id} });
      //}
      //await this.getEventStatus();
    },

    isRelay(item) {
      return item.team === 'RELAY_TIME_SLOTS' || item.team === 'RELAY_DISTANCE_SLOTS';
    },

    async createCustomGroup() {
      const groupName = window.prompt('Please enter the name of your group/team:');
      if (groupName) {
        const response = (await profileService.createGroupForEvent(this.event.id, { name: groupName })).data;
        this.$helpers.toastResponse(this, response, 'Successfully created your group.');
        if (response.status == 'OK') {
          this.$router.replace({ name: "profileGroupsEvent", params: { id: response.id, eventId: this.event.id} });
        }
      }
    },
  
    async joinCustomGroup() {
      const code = window.prompt('Please enter the join code of the group/team you want to join. Don\'t have a code? Contact the team captain to get one!');
      if (code) {
        const response = (await profileService.joinGroupForEvent(this.event.id, { code: code })).data;
        this.$helpers.toastResponse(this, response, 'Successfully joined the group.');
        if (response.status == 'OK') {
          this.$router.replace({ name: "profileGroupsEvent", params: { id: response.id, eventId: this.event.id} });
        }
      }
    },

    async customizeRaces() {
      var request = { race_ids: this.selectedRaceIds || []};
      await profileService.joinEvent(this.event.id, request);
      await this.getEventStatus();
    },

    async loadEvent(id) {
      var response = await eventService.get(id);
      this.event = response.data;
      var meta = {
        title: this.event.name + ' on ' + tenant.name,
        description: this.event.description,
        image: this.event.img,
      };
      EventBus.$emit('page-header-change', meta);
    },
    

  },
  computed: {
    isEmbedded() {
      return this.$route.query.view === 'embed' || this.$store.state.view == 'embed' || this.isEmbeddedInApp;
    },
    isEmbeddedInApp() {
      return this.$route.query.view === 'app' || this.$store.state.view == 'app';
    },
    onDemandRacesAvailable() {
      return (!this.status || !this.event || !this.event.races || !this.status.available_on_demand_race_ids || this.status.available_on_demand_race_ids.length == 0)
           ? []
           : this.event.races.filter(x => this.status.available_on_demand_race_ids.some(y => y === x.id) && (!this.status.on_demand_race_ids || !this.status.on_demand_race_ids.some(y => y === x.id)));
    },
    isConnected() {
      return this.status && this.status.connected;
    },
    breadcrumbItems() {
      return !this.event ? [] : [
        { text: this.event.name, exact: true, to: { name: 'event', params: {id: this.event.id}} },
        { text: 'Welcome', disabled: true },
      ];
    },
    ...mapGetters({
      user: "user"
    })
  },

};
</script>
<style lang="scss">

</style>

