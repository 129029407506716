<template>
    <v-card
      :color="color || $helpers.getGradientColor(index)"
      :class="isDark ? 'white--text' : 'black--text'"
      :href="item.external_url"
      :target="item.external_url && item.external_url.indexOf('blank') ? '_blank' : null"
      :to="hasClickListener || item.external_url ? null : {name: 'race', params: {id: event.id, raceId: item.id}}"
      @click="hasClickListener ? $emit('click', item) : null"
      >
      <v-card-title class="text--bold subtitle">
        {{item.name}}
      </v-card-title>
      <v-divider/>
      <v-card-text v-if="item.goal_desc" :class="isDark ? 'white--text' : 'black--text'">
        <vue-markdown class="markdown" :html="false">{{ item.goal_desc }}</vue-markdown>
      </v-card-text>
      <template v-else >
        <v-card-text :class="isDark ? 'white--text' : 'black--text'">
          <v-icon :color="textColor" small class="mr-2">{{ $helpers.getScoringIcon(item.scoring) }}</v-icon> 
          {{ $t('events.rules.scoring-methods-short.' + item.scoring) }}
          <span v-if="item.dist">• {{$t('events.race.goal')}} {{ $helpers.getGoalDistanceForDisplay($options, item, item.dist, event.unit) }}</span>
        </v-card-text>
        <v-card-text :class="isDark ? 'white--text' : 'black--text'">
          <span v-if="!item.activity_types || item.activity_types.length == 0">
            <v-icon :color="textColor" small class="mr-2">fa-ellipsis-h</v-icon>
            {{ $t('events.race.all-sports') }},
          </span>
          <span v-else v-for="(type, idx) in item.activity_types" :key="idx">
            <v-icon :color="textColor" v-if="idx==0" small class="mr-2">{{ $helpers.getActivityIcon(type) }}</v-icon>
            {{  idx == 0 ? $t('profile.activities.types.'+type) : $t('profile.activities.types.'+type).toLowerCase() }}
            <span v-if="idx != item.activity_types.length-1" class="mr-0">{{ $t('shared.or') }}</span>
            <span v-else class="mr-0">•</span>
          </span>
          {{item.team ? (isRelay ? $t('events.race.relay') : $t('events.race.team')) :$t('events.race.individual')}}
        </v-card-text>
      </template>
      <v-card-actions>
        <v-btn 
          text 
          :color="textColor" 
          :href="item.external_url"
          :target="item.external_url && item.external_url.indexOf('blank') ? '_blank' : null"
          :to="hasClickListener || item.external_url ? null : {name: 'race', params: {id: event.id, raceId: item.id}}"
          @click="hasClickListener ? $emit('click', item) : null"
          >
          {{$t('events.race.view-details')}}
        </v-btn>
      </v-card-actions>
    </v-card>
</template>

<script>
import siteData from '@/data/site.json'
import VueMarkdown from '@/components/VueMarkdown.vue'

export default {
  name: "RaceCard",
  components: {
    VueMarkdown,
  },
  props: {
    event: Object,
    item: Object,
    index: Number,
    color: String,
    to: Object,
  },
  emits: [
    'click', 
  ],
  data: function() {
    return {
      scoringMethods: siteData.scoring_methods,
      raceTypes: siteData.race_types,
    };
  },
  created() {
  },
  mounted() {
  },
  methods: {
  },
  computed: {
    isDark() {
      if (this.color) {
        return this.$helpers.isDarkColor(this.color);
      }
      return false;
    },
    isRelay() {
      return this.item.team === 'RELAY_TIME_SLOTS' || this.item.team === 'RELAY_DISTANCE_SLOTS';
    },
    textColor() {
      return this.isDark ? 'white' : 'black';
    },
    hasClickListener(){
      return this.$listeners && this.$listeners.click;
    }
  },
};
</script>

<style scoped>
</style>